﻿import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrdersService } from '../_services/orders.service';
import { FlexiBenefitService } from '../_services/flexibenefit.service';
import { AlertService } from '../_services/alert.service';
import { User } from '../_models/user';
import { OrdersModel } from '../_models/orders';
import { FlexiBenefits, NewFB } from '../_models/flexibenefit';
import { FragmencesModel } from '../_models/fragmences';
import { min } from 'rxjs/operators/min';
import { AuthenticationService, UserService } from '../_services/index';
import { NgForm } from '@angular/forms/src/directives/ng_form';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxConfirmBoxService } from 'ngx-confirm-box';
import { angularMath } from 'angular-ts-math';
import { t } from '@angular/core/src/render3';
import { ModalComponent } from '../modal/modal.component';
//import { Console } from 'console';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

// import {MatTooltipModule} from '@angular/material/tooltip';
declare var jquery: any;
declare var $: any;
declare var $dialogs: any;

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'home.component.html?v=${new Date().getTime()}'
})


export class HomeComponent implements OnInit {
    private msg: string;
    public loading = false;
    orderId = 0;
    event = '';
    ordersModel: OrdersModel;
    flexiBenefits: FlexiBenefits;
    NewFB: NewFB;
    fragmencesModel: FragmencesModel;
    inProressPage = 1;
    selectInProressPage = 1;
    newOrdersPage = 1;
    draftPage = 1;
    skuPage = 1;
    selectDraftPage = 1;
    selectDonePage = 1;
    donePage = 1;
    fbdonepage = 1;
    fbadminpage = 1;
    fbsearchId = '';
    fbid = 0;
    showSidePanel = 1;
    showChangePassword = 1;
    showFlexiBenefit = 0;
    fblocalsearchStatus = 'Status';
    fbsearchStatus = 'Select';
    fbFromDate: Date;
    fbToDate: Date;
    fbCustomSearch = false;
    saveFBForm: any;
    @ViewChild('myAttachment') myAttachment: ElementRef;
    currentUser: User;
    display = "none";
    currentUserCountryName = '';
    currentUserCountryCurrency = '';
    model: any = {};
    tempadminFBList: any = [];
    markerconstdisplay = "none";
    marketingdisable = false
    marketcheck = false;
    IsAdmin = false;
    selectedIndex = 0;
    //editOrCopyOrder = false;
    //editOrder = false;
    //copyOrder = false;
    actionOnOrder = "New"; // Edit / Copy / New
    //navigatingFromEdit = false;
    currentcustomerId = 0;

    bgColor = 'rgba(0,0,0,0.5)'; // overlay background color
    confirmHeading = "Confirmation";
    confirmContent = "Do you want to perform this action ?";
    confirmCanceltext = "Cancel";
    confirmOkaytext = "Okay";
    showOrderActionButton = true;
    //showOrderActionButton = false; //restrict users to place new orders

    //showFlexiActionButton = true;
    showFlexiActionButton = false; //restrict users to submit new claims
    // fbeditReceiptDate: Date;
    // fbeditCategory: any;
    // fbeditNTA: 0.00;
    // fbeditTA: 0.00;
    constructor(private ordersService: OrdersService,
        private flexibenefitService: FlexiBenefitService,
        private alertService: AlertService, private authenticationService: AuthenticationService,
        private router: Router, private toastr: ToastrService, private confirmBox: NgxConfirmBoxService, private dialog: MatDialog) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.ordersModel = new OrdersModel();
        this.flexiBenefits = new FlexiBenefits();
        this.fragmencesModel = new FragmencesModel();
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.marketcheck = this.currentUser.Marketconsent === "Y" ? true : false;
        localStorage.clear();
        this.authenticationService.login(this.currentUser.UserName, this.currentUser.Password);
        this.currentcustomerId = this.currentUser.CustomerId;
        let downUrl = "appdown";
        //navigate to down page
        this.IsAdmin = this.currentUser.Admin === 'Yes' ? true : false;
        //this.authenticationService.ValidateDownTime()
        //this.authenticationService.ValidateBatchProcessingDownTime()
        //    .subscribe(
        //         data => {
        //         if (data === true) {
        //             localStorage.clear();
        //             this.authenticationService.logout();
        //             this.router.navigate([downUrl]);
        //        }
        //     }
        //);

        this.authenticationService.ValidateDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        this.showOrderActionButton = false;
                    }
                }
            );

        this.authenticationService.ValidateBatchProcessingDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        this.showFlexiActionButton = false;
                    }
                }
            );


        this.loadOrders();
        this.loadFlexiBenefit();
        this.loadFBCategory();
        if (this.IsAdmin) {
            this.loadAllFlexiBenefit(this.fbCustomSearch);
        }
        //this.initializeNewFB();
        //this.loadDraftOrder();
        // this.loadAllFragmances();
        //this.loadUniqueBrands();
        this.loadUniqueBrandsByCountryCode(this.currentUser.CountryCode);
        this.loadCategories(this.currentUser.CountryCode);
        this.validateStoreVisiblity();
        this.getCustomerCountry();
        this.getCustomerCountryCurrency();
        this.getActiveFragrancesByCountry(this.currentUser.CountryCode);

        if (this.currentUser.CountryCode === 'MY') {
            console.log('loading MY Flexi Category List');
            this.flexiBenefits.CategoryDropDown = this.flexiBenefits.CategoryDropDownMY;
        } else {
            console.log('loading SG Flexi Category List');
            this.flexiBenefits.CategoryDropDown = this.flexiBenefits.CategoryDropDownSG;
        }

    }

    /*
    validateDownTime() {
        //let downUrl = "appdown";
        let downUrl = "login";
        //navigate to down page
        this.authenticationService.ValidateDownTime()
        //this.authenticationService.ValidateBatchProcessingDownTime()
             .subscribe(
                 data => {
                     if (data === true) {
                         localStorage.clear();
                         this.authenticationService.logout();
                         this.router.navigate([downUrl]);
                     }
                 }
        );
    }
    */

    //hideActionsDuringDowntime() {
    //    //let downUrl = "appdown";
    //    let downUrl = "login";
    //    //navigate to down page
    //    this.authenticationService.ValidateDownTime()
    //    //this.authenticationService.ValidateBatchProcessingDownTime()
    //         .subscribe(
    //             data => {
    //                 return data;
    //             }
    //    );
    //}


    //validateBatchProcessingDownTime() {
    //    this.authenticationService.ValidateBatchProcessingDownTime()
    //         .subscribe(
    //             data => {
    //                 return data;
    //             }
    //   );
    //}


    private getCustomerCountry() {
        this.ordersService.getCustomerCountry(this.currentUser.UserName)
            .subscribe(
                data => {
                    this.currentUserCountryName = data;
                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //  this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    private getCustomerCountryCurrency() {
        this.ordersService.getCustomerCountryCurrency(this.currentUser.UserName)
            .subscribe(
                data => {
                    this.currentUserCountryCurrency = data;
                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //  this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    private loadDraftOrder() {

        this.ordersService.getDraftOrder(this.currentUser.CustomerId, this.ordersModel.type.id)
            .subscribe(
                data => {
                    this.ordersModel.draft = data;
                    //this.ordersModel.savedOrder= data[0];
                },
                error => {
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);

                });
    }

    private loadOrders() {
        //this.loading = true;
        this.ordersService.getOrders(this.currentUser.CustomerId, this.ordersModel.type.id)
            .subscribe(
                data => {
                    this.loading = false;
                    this.ordersModel.orders = data;
                    this.ordersModel.devideOrders();
                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    private loadFBCategory() {
        for (let item of this.flexiBenefits.newFBList) {
            item.Category = 'Select'
        }
    }

    downloadFile(fbid) {
        this.flexibenefitService.downloadFile(fbid)
            .subscribe(
                data => {
                    //fbFile:File = data;
                    const blob = new Blob([data], { type: data.type }); // you can change the type
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    private loadFlexiBenefit() {
        //this.loading = true;

        this.flexiBenefits.CreateRequestAllowed = this.flexiBenefits.ClaimEntitlementValue < this.flexiBenefits.ClaimUtilisationValue;
        this.flexibenefitService.getFlexiBenefit(this.currentUser.CustomerId)
            .subscribe(
                data => {
                    this.loading = false;
                    this.flexiBenefits.doneNtaTotal = 0;
                    this.flexiBenefits.doneTaTotal = 0;
                    this.flexiBenefits.doneGrandTotal = 0;
                    this.flexiBenefits.done = data;
                    this.onLocalStatusChange(this.fblocalsearchStatus);
                    for (let item of this.flexiBenefits.done) {
                        // this.flexiBenefits.doneNtaTotal += item.NTA;
                        // this.flexiBenefits.doneTaTotal += item.TA;
                        item.isDeletedAllowed = item.Status == 'Approved' || item.Status == 'Rejected' ? false : true;
                    }
                    this.flexiBenefits.doneNtaTotal = 0.00;
                    this.flexiBenefits.doneTaTotal = 0.00;
                    this.flexiBenefits.doneGrandTotal = this.flexiBenefits.doneNtaTotal + this.flexiBenefits.doneTaTotal;
                    this.loadFlexiBenefitValues();
                    this.flexiBenefits.IsAdmin = this.currentUser.Admin === 'Yes' ? true : false;

                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    onLocalStatusChange(status) {
        this.flexiBenefits.doneNtaTotal = 0;
        this.flexiBenefits.doneTaTotal = 0;
        this.flexiBenefits.doneGrandTotal = 0;
        if (status === 'Status') {
            this.flexiBenefits.done2 = this.flexiBenefits.done;
        }
        else {
            if (status === 'P+A') {
                this.flexiBenefits.done2 = this.flexiBenefits.done.filter(a => a.Status === 'Pending' || a.Status === 'Approved');
            }
            else {
                this.flexiBenefits.done2 = this.flexiBenefits.done.filter(a => a.Status === status);
            }
            for (let item of this.flexiBenefits.done2) {
                this.flexiBenefits.doneNtaTotal += item.NTA;
                this.flexiBenefits.doneTaTotal += item.TA;
            }
            this.flexiBenefits.doneGrandTotal = this.flexiBenefits.doneNtaTotal + this.flexiBenefits.doneTaTotal;
            this.flexiBenefits.doneGrandTotal = parseFloat(this.flexiBenefits.doneGrandTotal.toFixed(2));
        }
        for (let item of this.flexiBenefits.done2) {
            if (item.Comments == null || item.Comments == "null" || item.Comments == undefined || item.Comments === "null" || item.Comments === null) {
                item.Comments = '';
            }
        }
    }

    loadFlexiBenefitValues() {
        this.flexibenefitService.getFlexiBenefitValues(this.currentUser.CustomerId)
            .subscribe(
                data => {
                    this.flexiBenefits.ClaimEntitlementValue = parseFloat(data.m_Item1.toFixed(2));
                    this.flexiBenefits.ClaimUtilisationValue = parseFloat(data.m_Item2.toFixed(2));
                    this.flexiBenefits.AllowedFlexiBenefitLimit = this.flexiBenefits.ClaimEntitlementValue - this.flexiBenefits.ClaimUtilisationValue;
                    this.flexiBenefits.AllowedFlexiBenefitLimit = parseFloat(this.flexiBenefits.AllowedFlexiBenefitLimit.toFixed(2));
                },
                error => {
                    this.loading = false;
                    this.errorMessage('Unable to fetch Flexi Benefit values.');
                });
    }

    private loadAllFlexiBenefit(isCustom) {
        //this.loading = true;
        //this.flexibenefitService.getAllFlexiBenefit(isCustom)
        this.flexibenefitService.getAllFlexiBenefit(this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    this.flexiBenefits.adminFBList = data;
                    this.tempadminFBList = this.flexiBenefits.adminFBList;

                    this.tempadminFBList = this.tempadminFBList.filter(
                        a => a.Archive != 'Y'
                    );

                    for (let item of this.tempadminFBList) {
                        if (item.Comments == null || item.Comments == "null" || item.Comments == undefined || item.Comments === "null" || item.Comments === null) {
                            item.Comments = '';
                        }
                    }
                    for (let item of this.flexiBenefits.adminFBList) {
                        this.flexiBenefits.doneAdminNtaTotal += item.NTA;
                        this.flexiBenefits.doneAdminTaTotal += item.TA;
                        item.isApproveAllowed = item.Status == 'Approved' ? false : true;
                        item.isRejectAllowed = item.Status == 'Rejected' ? false : true;
                    }
                    this.flexiBenefits.doneAdminGrandTotal = this.flexiBenefits.doneAdminNtaTotal + this.flexiBenefits.doneAdminTaTotal;

                },
                error => {
                    this.loading = false;
                    this.errorMessage(error.message);
                });

    }

    private onChange() {
        this.loading = true;
        this.ordersModel.range.id = '0';
        this.ordersModel.SKUSearch = "";

        this.ordersService.getFilterBy(this.fragmencesModel.GetDDData(), this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    for (let i of data) {
                        i.OrderQty = this.ordersModel.qtys[0];
                        i.totalPrice = i.OrderQty * i.RetailPrice;
                        i.totalPrice = parseFloat(i.totalPrice.toFixed(2));
                    }
                    this.ordersModel.sku = data;
                },
                error => {
                    this.loading = false;
                    //this.alertService.error(error.message);
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.errorMessage(error.message);
                });
    }

    addSKU(articleCode) {
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue;
        this.ordersModel.addSKU(articleCode, totalBonus, this.ordersModel.type.id);
    }

    removeGoods(index) {
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue;
        this.ordersModel.removeOrders(index, totalBonus, this.ordersModel.type.id);
        //this.ordersModel.SelectedStore = 0;
    }

    onSKUEnter(input) {

    }

    SKUSearch() {
        this.loading = true;
        this.fragmencesModel.category = 0;
        this.fragmencesModel.subCategory = 0;
        this.fragmencesModel.merchandiseCategory = 0;
        this.fragmencesModel.brand = 0;
        this.ordersModel.UniqueBrand = 0;
        if (this.ordersModel.SKUSearch != null && this.ordersModel.SKUSearch.length > 0) {


            this.ordersService.getSKUSearch(this.ordersModel.SKUSearch.trim(), this.currentUser.UserName, this.currentUser.CountryCode)
                .subscribe(
                    data => {
                        this.loading = false;
                        for (let i of data) {
                            i.OrderQty = this.ordersModel.qtys[0];
                            i.totalPrice = i.OrderQty * i.RetailPrice;
                            i.totalPrice = parseFloat(i.totalPrice.toFixed(2));
                        }
                        this.ordersModel.sku = data;
                    },
                    error => {
                        this.loading = false;
                        // this.alertService.error(error.message);
                        // setTimeout(() => {
                        //     this.alertService.clearMessage();
                        // }, 5000);
                        this.errorMessage(error.message);
                    });
        }
        else {
            this.errorMessage('Please input to search box.');
        }
    }

    PriceChange() {
        if (this.ordersModel.range.id !== '0') {
            this.fragmencesModel.category = 0;
            this.fragmencesModel.subCategory = 0;
            this.fragmencesModel.merchandiseCategory = 0;
            this.fragmencesModel.brand = 0;
            this.ordersModel.SKUSearch = "";
            this.ordersModel.UniqueBrand = 0;
            this.loading = true;
            switch (this.ordersModel.range.id) {
                case '1':
                    this.ordersModel.priceRange.min = 0;
                    this.ordersModel.priceRange.max = 10;
                    break;
                case '2':
                    this.ordersModel.priceRange.min = 11;
                    this.ordersModel.priceRange.max = 20;
                    break;
                case '3':
                    this.ordersModel.priceRange.min = 21;
                    this.ordersModel.priceRange.max = 50;
                    break;
                case '4':
                    this.ordersModel.priceRange.min = 51;
                    this.ordersModel.priceRange.max = 100;
                    break;
                case '5':
                    this.ordersModel.priceRange.min = 101;
                    this.ordersModel.priceRange.max = 500;
                    break;
                case '6':
                    this.ordersModel.priceRange.min = 501;
                    this.ordersModel.priceRange.max = 1000;
                    break;
                case '7':
                    this.ordersModel.priceRange.min = 1000;
                    this.ordersModel.priceRange.max = 99999;
                    break;
                default:
                    break;
            }
            this.ordersService.getPriceRangeSearch(this.ordersModel.priceRange, this.currentUser.UserName, this.currentUser.CountryCode)
                .subscribe(
                    data => {
                        this.loading = false;
                        for (let i of data) {
                            i.OrderQty = this.ordersModel.qtys[0];
                            i.totalPrice = i.OrderQty * i.RetailPrice;
                            i.totalPrice = parseFloat(i.totalPrice.toFixed(2));
                        }
                        this.ordersModel.sku = data;
                    },
                    error => {
                        this.loading = false;
                        // this.alertService.error(error.message);
                        // setTimeout(() => {
                        //     this.alertService.clearMessage();
                        // }, 5000);
                        this.errorMessage(error.message);
                    });
        }
    }

    BrandSelected() {
        this.loading = true;
        this.fragmencesModel.category = 0;
        this.fragmencesModel.subCategory = 0;
        this.fragmencesModel.merchandiseCategory = 0;
        this.fragmencesModel.brand = 0;
        this.ordersModel.SKUSearch = "";
        this.ordersModel.range.id = '0';
        this.ordersService.getAllFragrancesByBrand(this.ordersModel.UniqueBrand, this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    for (let i of data) {
                        i.OrderQty = this.ordersModel.qtys[0];
                        i.totalPrice = i.OrderQty * i.RetailPrice;
                        i.totalPrice = parseFloat(i.totalPrice.toFixed(2));
                    }
                    this.ordersModel.sku = data;
                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });

    }

    isPlaceOrderDisabled() {
        //console.log("isPlaceOrderDisabled : " + this.ordersModel.newOrders.length + "/" + this.actionOnOrder + "/" + this.currentUser.ComplimentaryGoodsValue + "/" + this.ordersModel.totalOrderColor);

        if (this.ordersModel.newOrders.length === 0 || (this.actionOnOrder == 'New' && this.currentUser.ComplimentaryGoodsValue <= 0) || (this.actionOnOrder == 'Copy' && this.currentUser.ComplimentaryGoodsValue <= 0) || this.ordersModel.totalOrderColor == 'red') {
            return true;
        }
        else {
            return false;
        }

    }

    isDraftDisabled() {
        //console.log("isDraftDisabled : " + this.ordersModel.newOrders.length + "/" + this.actionOnOrder + "/" + this.ordersModel.totalOrderColor);

        if (this.ordersModel.newOrders.length === 0 || this.actionOnOrder == 'Edit' || this.actionOnOrder == 'Copy' || this.ordersModel.totalOrderColor == 'red') {
            return true;
        }
        else {
            return false;
        }

    }
    onTypeChange() {
        this.ordersModel.selectedInprogress = { 'orders': { '$values': [] } };
        this.ordersModel.selectedDraft = { 'orders': { '$values': [] } };
        this.ordersModel.selectedDone = { 'orders': { '$values': [] } };
        this.ngOnInit();
    }

    cancel() {
        //console.log(this.actionOnOrder);
        //this.editOrCopyOrder = false;

        this.ordersModel.clearCurrentOrder();
        this.loadOrders();
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue;
        this.ordersModel.getTotalQty(totalBonus, this.ordersModel.type.id);

        if (this.actionOnOrder == 'Edit')
            $('#Progress').tab('show');

        if (this.actionOnOrder == 'Copy')
            $('#Past').tab('show');

        this.actionOnOrder = 'New';
    }

    refresh() {
        //console.logconsole.log(this.actionOnOrder);
        //this.editOrCopyOrder = false;

        this.ordersModel.clearCurrentOrder();
        this.loadOrders();
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue;
        this.ordersModel.getTotalQty(totalBonus, this.ordersModel.type.id);
        //this.actionOnOrder = 'New';
    }
    saveDraft() {
        if (this.validateStore()) {
            if (this.ordersModel.totalOrderColor !== 'red') {
                this.loading = true;

                for (const i of this.ordersModel.newOrders) {
                    i.OrderQty = i.OrderQty;
                }

                var yyyy = new Date().getFullYear().toString();
                var mm = (new Date().getMonth() + 1).toString(); // getMonth() is zero-based         
                var dd = new Date().getDate().toString();
                var hh = new Date().getHours().toString();
                var mn = new Date().getMinutes().toString();
                var sc = new Date().getSeconds().toString();

                let formData = new FormData();
                formData.append('OrderMasterId', this.ordersModel.orderMasterId.toString());
                formData.append('OrderQty', this.ordersModel.totalOrderQty.toString());
                formData.append('OrderStatus', 'Draft');
                formData.append('OrderDate', (((dd[1] ? dd : "0" + dd[0]) + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + yyyy + "." + hh + "." + mn + "." + sc).toString()));
                formData.append('TotalOrderValue', this.ordersModel.totalOrderPrice.toString());
                formData.append('Currency', this.currentUserCountryCurrency.toString());
                formData.append('Ordertype', this.ordersModel.type.id.toString());
                formData.append('CustomerId', this.currentUser.CustomerId.toString());
                formData.append('IsDraft', 'true');

                //formData.append('StoreCode', (this.ordersModel.SelectedStore != 0 ? this.ordersModel.SelectedStore.toString() : ''));
                if (this.ordersModel.StoreLocatorVisible) {
                    formData.append('StoreCode', (this.ordersModel.SelectedStore != 0 ? this.ordersModel.SelectedStore.toString() : ''));
                } else {
                    formData.append('StoreCode', '');
                }

                formData.append('orders', JSON.stringify(this.ordersModel.newOrders));

                this.ordersService.placeOrder(formData)
                    .subscribe(
                        data => {
                            //this.loading = false;
                            // this.alertService.success('Draft Saved Successfully', false);
                            // setTimeout(() => {
                            //     this.alertService.clearMessage();
                            // }, 5000);

                            this.ordersModel.clearCurrentOrder();
                            this.loadOrders();
                            //this.loadDraftOrder();

                            this.successMessage("Order has been saved as 'Draft'");

                        },
                        error => {
                            this.loading = false;
                            // this.alertService.error(error.message);
                            // setTimeout(() => {
                            //     this.alertService.clearMessage();
                            // }, 5000);
                            this.errorMessage(error.message);

                        });
            }
            else {
                // this.alertService.error('Order value exceeds available credits in your account');
                // setTimeout(() => {
                //     this.alertService.clearMessage();
                // }, 5000);
                this.errorMessage('Order value exceeds available credits in your account.');
            }
        }
    }

    validateMarketPlace() {
        if (this.currentUser.IsFirstOrderPlaced == false)
            this.markerconstdisplay = 'block';
        else {
            this.placeOrder();
            /* added for the error*/
            this.authenticationService.login(this.currentUser.UserName, this.currentUser.Password)
                .subscribe(
                    data => {
                        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                        this.loadOrders();
                        //setTimeout(() => {
                        //    this.alertService.clearMessage();
                        //}, 5000);
                    },
                    error => {
                        //this.alertService.error(error);
                        this.errorMessage(error.message);
                        this.loading = false;
                    });
        }
    }

    validateStore() {
        var result = true;
        if (this.ordersModel.StoreLocatorVisible) {
            if (this.ordersModel.SelectedStore == 0) {
                this.errorMessage('Please select Store.');
                //this.alertService.error('Please select Store');
                //setTimeout(() => {
                //  this.alertService.clearMessage();
                //}, 5000);
                result = false;
            }
        }
        return result;
    }

    placeOrder() {
        if (this.validateStore()) {
            if (this.ordersModel.totalOrderColor !== 'red') {
                this.loading = true;

                var fragrance = undefined;
                var FragrancesDataUnAvailable = [];
                var limitedOrderArticlesFound = false;
                var limitedOrderArticlesFoundMessage = "";

                for (const i of this.ordersModel.newOrders) {

                    fragrance = this.ordersModel.getActiveFragrances.filter(a => a.ArticleCode == i.ArticleCode)[0];
                    //fragrance = this.ordersService.getFragranceByCodeCountry(i.ArticleCode, i.Country);

                    i.OrderQty = i.OrderQty;

                    if (fragrance == null || fragrance == undefined) {
                        FragrancesDataUnAvailable.push(i);
                    } else if (i.Brand == 'SKS' && i.OrderQty > 1) {
                        limitedOrderArticlesFound = true;
                        limitedOrderArticlesFoundMessage = " - Allowed quantity per order for Article is 1.<br>";
                    }
                    //} else if (i.ArticleCode == 'SKS82247369L' && i.OrderQty > 1) {
                    //    limitedOrderArticlesFound = true;
                    //    limitedOrderArticlesFoundMessage += " - Allowed quantity per order for Article SKS82247369L is 1.<br>";
                    //} else if (i.ArticleCode == 'SKS82247382L' && i.OrderQty > 2) {
                    //    limitedOrderArticlesFound = true;
                    //    limitedOrderArticlesFoundMessage += " - Allowed quantity per order for Article SKS82247382L is 2.<br>";
                    //}
                }

                if (limitedOrderArticlesFound) {
                    this.errorMessage("Maximum allowed quantity exceeded.<br>" + limitedOrderArticlesFoundMessage);
                } else if (FragrancesDataUnAvailable.length > 0) {
                    var msg = 'Following Articles are not available :';
                    FragrancesDataUnAvailable.forEach(element => {
                        msg = msg + ' ' + element.ArticleCode + ';';
                    });

                    this.errorMessage(msg);

                } else {

                    var yyyy = new Date().getFullYear().toString();
                    var mm = (new Date().getMonth() + 1).toString(); // getMonth() is zero-based         
                    var dd = new Date().getDate().toString();
                    var hh = new Date().getHours().toString();
                    var mn = new Date().getMinutes().toString();
                    var sc = new Date().getSeconds().toString();
                    //this.loading = true;
                    let formData = new FormData();
                    formData.append('OrderMasterId', this.ordersModel.orderMasterId.toString());
                    formData.append('OrderQty', this.ordersModel.totalOrderQty.toString());
                    formData.append('OrderStatus', 'In Progress');
                    formData.append('OrderDate', ((dd[1] ? dd : "0" + dd[0]) + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + yyyy + "." + hh + "." + mn + "." + sc).toString());
                    formData.append('TotalOrderValue', this.ordersModel.totalOrderPrice.toString());
                    formData.append('Currency', this.currentUserCountryCurrency.toString());
                    formData.append('Ordertype', this.ordersModel.type.id.toString());
                    formData.append('CustomerId', this.currentUser.CustomerId.toString());
                    formData.append('IsDraft', 'false');
                    //formData.append('StoreCode', (this.ordersModel.SelectedStore != 0 ? this.ordersModel.SelectedStore.toString() : ''));
                    if (this.ordersModel.StoreLocatorVisible) {
                        formData.append('StoreCode', (this.ordersModel.SelectedStore != 0 ? this.ordersModel.SelectedStore.toString() : ''));
                    } else {
                        formData.append('StoreCode', '');
                    }
                    formData.append('orders', JSON.stringify(this.ordersModel.newOrders));
                    this.ordersService.placeOrder(formData)
                        .subscribe(
                            data => {
                                if (data == true || data.m_Item1) {
                                    //this.loading = false;
                                    //this.alertService.success('Order Placed Successfully', false);
                                    this.actionOnOrder = 'New';
                                    this.successMessage('Order has been Placed Successfully.');
                                    this.ordersModel.clearCurrentOrder();
                                    this.ordersModel.SelectedStore = 0;
                                    //this.editOrCopyOrder = false;
                                    //this.editOrder = false;
                                    //this.copyOrder = false;
                                    // this.ordersModel.draftOrderMasterId = 0;
                                    this.authenticationService.login(this.currentUser.UserName, this.currentUser.Password)
                                        .subscribe(
                                            data => {
                                                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                                this.loadOrders();
                                                //setTimeout(() => {
                                                //    this.alertService.clearMessage();
                                                //}, 5000);
                                            },
                                            error => {
                                                //this.alertService.error(error);
                                                this.errorMessage(error.message);
                                                this.loading = false;
                                            });
                                }
                                else {
                                    this.errorMessage(data.m_Item2);
                                }

                            },
                            error => {
                                this.loading = false;
                                // this.alertService.error(error.message);
                                // setTimeout(() => {
                                //     this.alertService.clearMessage();
                                // }, 5000);
                                this.errorMessage(error.message);

                            });
                }
            } else {
                this.loading = false;
                // this.alertService.error();
                // setTimeout(() => {
                //     this.alertService.clearMessage();
                // }, 5000);
                this.errorMessage('Order value exceeds available credits in your account');
            }
        }
    }

    onQtyChange(index) {
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue;
        this.ordersModel.onQtyChange(index, totalBonus, this.ordersModel.type.id);
    }

    onFileSelected(event, index) {
        var fbfile = <File>event.target.files[0];
        this.flexiBenefits.newFBList[index].FileName = fbfile.name;
        this.flexiBenefits.newFBList[index].Attachment = fbfile;
        console.log(this.flexiBenefits.newFBList[index]);
    }

    onFBCatChange(index) {
        var tempfb = this.flexiBenefits.CategoryDropDown.filter(a => a.CategoryName == this.flexiBenefits.newFBList[index].Category)[0];
        console.log(tempfb);
        this.flexiBenefits.newFBList[index].NTA = 0.00;
        this.flexiBenefits.newFBList[index].TA = 0.00;
        this.flexiBenefits.newFBList[index].NTAFlag = !tempfb.NTAFlag;
        this.flexiBenefits.newFBList[index].TAFlag = !tempfb.TAFlag;
        this.calculateDraftFBTotal();
    }

    calculateDraftFBTotal() {
        this.flexiBenefits.draftNtaTotal = 0;
        this.flexiBenefits.draftTaTotal = 0;
        this.flexiBenefits.draftGrandTotal = 0;
        for (let item of this.flexiBenefits.newFBList) {
            this.flexiBenefits.draftNtaTotal += item.NTA;
        }
        for (let item of this.flexiBenefits.newFBList) {
            this.flexiBenefits.draftTaTotal += item.TA;
        }
        this.flexiBenefits.draftNtaTotal = parseFloat(this.flexiBenefits.draftNtaTotal.toFixed(2));
        this.flexiBenefits.draftTaTotal = parseFloat(this.flexiBenefits.draftTaTotal.toFixed(2));
        this.flexiBenefits.draftGrandTotal = this.flexiBenefits.draftNtaTotal + this.flexiBenefits.draftTaTotal;
        this.flexiBenefits.draftGrandTotal = parseFloat(this.flexiBenefits.draftGrandTotal.toFixed(2));

    }
    ResetFlexiBenefitForm(IsResetSearchRequired) {
        this.flexiBenefits.newFBList = [
            {
                Id: 0,
                ReceiptDate: '',
                Category: 'Select',
                NTA: 0.00,
                TA: 0.00,
                NTAFlag: true,
                TAFlag: true,
                FileName: '',
                Status: '',
                CustId: '',
                Attachment: File
            },
            {
                Id: 0,
                ReceiptDate: '',
                Category: 'Select',
                NTA: 0.00,
                TA: 0.00,
                NTAFlag: true,
                TAFlag: true,
                FileName: '',
                Status: '',
                CustId: '',
                Attachment: File
            },
            {
                Id: 0,
                ReceiptDate: '',
                Category: 'Select',
                NTA: 0.00,
                TA: 0.00,
                NTAFlag: true,
                TAFlag: true,
                FileName: '',
                Status: '',
                CustId: '',
                Attachment: File
            }
        ];
        this.flexiBenefits.draftNtaTotal = 0;
        this.flexiBenefits.draftTaTotal = 0;
        this.flexiBenefits.draftGrandTotal = 0;
        this.loadFlexiBenefit();
        this.loadFlexiBenefitValues();
        this.resetsearchFlexiBenefit(IsResetSearchRequired);
        this.loadAllFlexiBenefit(this.fbCustomSearch);
    }

    RefreshFlexiBenefitForm(resetSearchRequired) {
        this.loadFlexiBenefit();
        this.loadFlexiBenefitValues();
        this.resetsearchFlexiBenefit(resetSearchRequired);
        this.loadAllFlexiBenefit(this.fbCustomSearch);
        this.fblocalsearchStatus = 'Status';
        this.onLocalStatusChange(this.fblocalsearchStatus);
    }

    AddNewFlexiBenefit() {
        this.flexiBenefits.newFBList.push({
            Id: 0,
            ReceiptDate: '',
            Category: 'Select',
            NTA: 0.00,
            TA: 0.00,
            NTAFlag: true,
            TAFlag: true,
            FileName: '',
            Status: '',
            CustId: '',
            Attachment: File
        });
    }

    validateFlexiBenefit() {
        this.loading = true;
        if (this.flexiBenefits.draftGrandTotal !== undefined) {
            if (this.flexiBenefits.draftGrandTotal <= this.flexiBenefits.AllowedFlexiBenefitLimit) {
                this.flexiBenefits.draft = this.flexiBenefits.newFBList.filter(a => a.NTA > 0 || a.TA > 0);
                var isValid = true;
                var fileSizeExceeded = "";
                if (this.flexiBenefits.draft.length > 0) {
                    for (let item of this.flexiBenefits.draft) {
                        if ((item.NTA > 0 || item.TA > 0) && item.Attachment.arguments === null) {
                            isValid = false;
                            break;
                        }
                        if (item.ReceiptDate === '' || item.Category === 'Select') {
                            isValid = false;
                            break;
                        }

                        if (item.Attachment.size > 5242880) {
                            fileSizeExceeded = item.Attachment.name + " exceeded 5MB size." + "<BR>";
                        }
                    }

                    if (isValid && fileSizeExceeded == "") {
                        this.saveFlexiBenefit();
                    }
                    else if (!isValid) {
                        this.errorMessage('One or more input is missing, please input and try again.');
                    }
                    else if (fileSizeExceeded !== "") {
                        this.errorMessage(fileSizeExceeded);
                    }
                    else {
                        this.errorMessage('One or more input is missing, please input and try again.');
                    }
                }
                else {
                    this.errorMessage('Please input atleast a single record.');
                }


            }
            else {
                this.errorMessage('Your claim value ' + this.flexiBenefits.draftGrandTotal + ' is higher than allowed limit ' + this.flexiBenefits.AllowedFlexiBenefitLimit);
            }
        }
        else {
            this.errorMessage('Please input atleast a single record.');
        }

    }

    saveFlexiBenefit() {

        let formData = new FormData();
        for (let item of this.flexiBenefits.newFBList) {
            item.Id = 0;
            item.CustId = this.currentcustomerId;
            item.Status = 'Pending';
            // formData.append(item.FileName, item.Attachment);
            // formData.append('Item','TestingFormData');
        }
        this.flexiBenefits.draft = this.flexiBenefits.newFBList.filter(a => a.NTA > 0 || a.TA > 0);
        for (let i = 0; i < this.flexiBenefits.draft.length; i++) {
            formData.append(this.flexiBenefits.draft[i].FileName, this.flexiBenefits.draft[i].Attachment);
            formData.append(i.toString(), JSON.stringify(this.flexiBenefits.draft[i]));
        }
        this.loading = true;
        this.flexibenefitService.saveFlexiBenefit(formData)
            .subscribe(
                data => {
                    if (data == true || data.m_Item1) {
                        //this.alertService.success('Personal Email Updated Successfully', false);
                        this.loading = false;
                        //setTimeout(() => {
                        //    this.alertService.clearMessage();
                        //}, 5000);

                        this.successMessage('Flexi Benefit Info Saved');
                        this.ResetFlexiBenefitForm(1);
                        this.loadFlexiBenefit()
                        if (this.IsAdmin) {
                            this.resetsearchFlexiBenefit(1);
                            this.loadAllFlexiBenefit(this.fbCustomSearch);
                        }
                    }
                    else {
                        this.errorMessage(data.m_Item2);
                    }

                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });
    }

    // approveFlexiBenefitConfirm(fbid){
    //     this.event = 'approveFlexiBenefit';
    //     this.fbid = fbid;
    //     this.confirmContent = "Are you sure, you want to approve this claim ?";
    //     this.confirmOkaytext = "Approve";
    //     this.confirmBox.show();
    // }

    SubmitClaimConfirm() {
        this.authenticationService.ValidateBatchProcessingDownTime()
            .subscribe(
                data => {
                    if (data === true) {
                        localStorage.clear();
                        this.authenticationService.logout();
                        this.router.navigate(["login"]);
                    } else {
                        this.event = 'submitClaimConfirm';
                        this.confirmBox.show();
                    }
                }
            );
    }

    HideSidePanel() {
        this.showSidePanel = 0;
    }

    ShowSidePanel() {
        this.showSidePanel = 1;
    }

    approveFlexiBenefit(flexiid, approvalComment) {
        let formData = new FormData();
        formData.append('flexiid', flexiid);
        formData.append('approvereject', '1');
        formData.append('comments', approvalComment);
        this.flexibenefitService.approverejectFlexiBenefit(formData)//flexiid,1,"Approved")
            .subscribe(
                result => {
                    if (result) {
                        var item = this.flexiBenefits.adminFBList.filter(a => a.Id == flexiid)[0];
                        // this.flexiBenefits.adminFBList.filter(
                        //     a => a.Id == this.fbid
                        // )[0].Status = 'Approved';
                        item.Status = 'Approved';
                        item.isApproveAllowed = item.Status == 'Approved' ? false : true;
                        item.isRejectAllowed = item.Status == 'Rejected' ? false : true;
                        //this.RefreshFlexiBenefitForm(0);
                        //this.loadFlexiBenefit();
                        this.loadFlexiBenefitValues();
                        this.successMessage('Flexi Benefit Approved');
                    }
                    else {
                        this.errorMessage("Record doesn't exists, kindly refresh");
                    }
                },
                error => {
                    this.loading = false;
                    this.errorMessage("Record doesn't exists, kindly refresh");
                });
    }

    // rejectFlexiBenefitConfirm(fbid, rejectionComment){
    //     if(rejectionComment === undefined || rejectionComment === null || rejectionComment.trim().length<1){
    //         this.errorMessage("Please add rejection comments.");
    //     }
    //     else{
    //         this.event = 'rejectFlexiBenefit';
    //         this.fbid = fbid;
    //         this.rejectionComments = rejectionComment;
    //         this.confirmContent = "Are you sure, you want to reject this claim ?";
    //         this.confirmOkaytext = "Reject";
    //         this.confirmBox.show();
    //     }

    // }

    rejectFlexiBenefit(flexiid, rejectionComment) {

        if (rejectionComment === undefined || rejectionComment === null || rejectionComment.trim().length < 1) {
            this.errorMessage("Please add rejection comments.");
        }
        else {
            rejectionComment = rejectionComment.replace(new RegExp('\n', 'g'), '\n');
            //.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
            let formData = new FormData();
            formData.append('flexiid', flexiid);
            formData.append('approvereject', '0');
            formData.append('comments', rejectionComment);

            //this.flexibenefitService.approverejectFlexiBenefit(flexiid,0, rejectionComment)
            this.flexibenefitService.approverejectFlexiBenefit(formData)
                .subscribe(
                    result => {
                        if (result) {
                            var item = this.flexiBenefits.adminFBList.filter(a => a.Id == flexiid)[0];
                            item.Status = 'Rejected';
                            item.isApproveAllowed = item.Status == 'Approved' ? false : true;
                            item.isRejectAllowed = item.Status == 'Rejected' ? false : true;
                            this.loadFlexiBenefit();
                            this.loadFlexiBenefitValues();
                            this.successMessage('Flexi Benefit Rejected');
                        }
                        else {
                            this.errorMessage("Record doesn't exists, kindly refresh");
                        }
                    },
                    error => {
                        this.loading = false;
                        this.errorMessage("Record doesn't exists, kindly refresh");
                    });
        }
    }

    deleteFlexiBenefitConfirm(fbid) {
        this.event = 'deleteFlexiBenefit';
        this.fbid = fbid;
        this.confirmContent = "Are you sure, you want to delete this claim ?";
        this.confirmOkaytext = "Delete";
        this.confirmBox.show();
    }

    deleteFlexiBenefit() {
        this.flexibenefitService.deleteFlexiBenefit(this.fbid)
            .subscribe(
                result => {
                    if (result) {
                        this.loadFlexiBenefit();
                        this.resetsearchFlexiBenefit(1);
                        this.loadAllFlexiBenefit(this.fbCustomSearch);
                        this.successMessage('Flexi Benefit Deleted');
                    }
                    else {
                        this.errorMessage("Record is already approved, can't delete.");
                    }
                },
                error => {
                    this.loading = false;
                    this.errorMessage("Unknown Error has occured.");
                });
    }

    deleteTempFlexiBenefit(fb) {
        this.flexiBenefits.newFBList = this.flexiBenefits.newFBList.filter(a => a !== fb);
        this.calculateDraftFBTotal();
    }

    exportFlexiBenefit() {
        if (this.fbFromDate === undefined || this.fbToDate === undefined || this.fbFromDate === null || this.fbToDate === null) {
            this.errorMessage("Please select the From and To Date");
        }
        else {
            this.flexibenefitService.exportFlexiBenefit(this.fbFromDate, this.fbToDate, this.fbsearchStatus, this.fbsearchId)
                .subscribe(
                    data => {
                        const blob = new Blob([data], { type: data.type }); // you can change the type
                        const url = window.URL.createObjectURL(blob);
                        window.open(url);
                    },
                    error => {
                        this.loading = false;
                        this.errorMessage(error.message);
                    });
        }
    }

    searchFlexiBenefit() {
        //this.flexibenefitService.getAllFlexiBenefit(true)
        this.flexibenefitService.getAllFlexiBenefit(this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    this.flexiBenefits.adminFBList = data;
                    this.tempadminFBList = this.flexiBenefits.adminFBList;
                    for (let item of this.tempadminFBList) {
                        if (item.Comments == null || item.Comments == "null" || item.Comments == undefined || item.Comments === "null" || item.Comments === null) {
                            item.Comments = '';
                        }
                    }
                    for (let item of this.flexiBenefits.adminFBList) {
                        this.flexiBenefits.doneAdminNtaTotal += item.NTA;
                        this.flexiBenefits.doneAdminTaTotal += item.TA;
                        item.isApproveAllowed = item.Status == 'Approved' ? false : true;
                        item.isRejectAllowed = item.Status == 'Rejected' ? false : true;

                    }
                    this.flexiBenefits.doneAdminGrandTotal = this.flexiBenefits.doneAdminNtaTotal + this.flexiBenefits.doneAdminTaTotal;
                    console.log(this.fbsearchId);
                    if (typeof this.fbsearchId != 'undefined' && this.fbsearchId && this.fbsearchId.toString().trim().length > 0) {
                        this.tempadminFBList = this.tempadminFBList.filter(
                            a => a.Id == this.fbsearchId || a.CustName.includes(this.fbsearchId)
                        );
                    }

                    if (this.fbsearchStatus != 'Select') {
                        if (this.fbsearchStatus === 'P+A') {
                            this.tempadminFBList = this.tempadminFBList.filter(
                                a => a.Status == 'Pending' || a.Status == 'Approved'
                            );
                        }
                        else {
                            this.tempadminFBList = this.tempadminFBList.filter(
                                a => a.Status == this.fbsearchStatus
                            );
                        }
                    }
                    console.log(this.fbFromDate);
                    if (this.fbFromDate != undefined) {
                        this.tempadminFBList = this.tempadminFBList.filter(
                            a => a.CreatedDate.split('T')[0] >= this.fbFromDate
                        );
                    }
                    console.log(this.fbToDate);
                    if (this.fbToDate != undefined) {
                        this.tempadminFBList = this.tempadminFBList.filter(
                            a => a.CreatedDate.split('T')[0] <= this.fbToDate
                        );
                    }

                },
                error => {
                    this.loading = false;
                    this.errorMessage(error.message);
                });


    }

    resetsearchFlexiBenefit(resetSearchRequired) {
        //console.log(this.fbsearchId);
        if (resetSearchRequired !== 0) {
            this.fbsearchId = '';
            this.fbsearchStatus = 'Select';
            this.fbFromDate = null;
            this.fbToDate = null;
        }
        this.loadAllFlexiBenefit(this.fbCustomSearch);
        //this.tempadminFBList = this.flexiBenefits.adminFBList;
    }

    /*
    private loadAllFragmances() {
        this.ordersService.getFragmencesFilter()
            .subscribe(
                data => {
                    this.fragmencesModel.fragmences = data;
                    this.fragmencesModel.merchandiseCategory = this.fragmencesModel.fragmences.MechantiseCategory[0];
                    this.fragmencesModel.subCategory = this.fragmencesModel.fragmences.SubCategory[0];
                    this.fragmencesModel.category = this.fragmencesModel.fragmences.Category[0];
                    this.fragmencesModel.brand = this.fragmencesModel.fragmences.Brand[0];
                    this.onChange();
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }
    */

    private getStoreObject(storeCode, storeName) {
        var obj: any = {};
        obj.StoreCode = storeCode;
        obj.StoreName = storeName;
        return obj;
    }

    private getBrandObject(brand, brandDesc) {
        var obj: any = {};
        obj.Brand = brand;
        obj.BrandDesc = brandDesc;
        return obj;
    }

    private convertDicitionaryToList(dictionary, entityType) {
        var arr = [];

        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                if (entityType == 'Store') {
                    arr.push(this.getStoreObject(key, dictionary[key]));
                }
                if (entityType == 'Brand') {
                    arr.push(this.getBrandObject(key, dictionary[key]));
                }
            }
        }
        return arr;
    }

    private loadStores() {
        this.ordersService.getStores(this.currentUser.UserName, this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.ordersModel.ListOfStores = data;

                    this.ordersModel.SelectedStore = 0;
                    this.ordersModel.devideOrders();
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private validateStoreVisiblity() {
        this.ordersService.storeLocatorVisibleCheck(this.currentUser.UserName)
            .subscribe(
                data => {
                    this.ordersModel.StoreLocatorVisible = data;
                    if (this.ordersModel.StoreLocatorVisible) {
                        this.loadStores();
                    }
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    /*
    private loadUniqueBrands() {
      this.ordersService.getUniqueBrandsFilter()
            .subscribe(
                data => {
                    this.ordersModel.ListOfBrands = data;
                    this.ordersModel.UniqueBrand = 0;
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }
    */


    private getActiveFragrancesByCountry(countryCode) {
        this.ordersService.getActiveFragrancesByCountry(countryCode)
            .subscribe(
                data => {
                    this.ordersModel.getActiveFragrances = data;
                },
                error => {
                    this.errorMessage(error.message);
                });
    }


    private loadUniqueBrandsByCountryCode(countryCode) {
        //console.log(countryCode);
        this.ordersService.getUniqueBrandsByCountryCode(this.currentUser.UserName, countryCode)
            .subscribe(
                data => {
                    this.ordersModel.ListOfBrands = data;
                    this.ordersModel.UniqueBrand = 0;
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private loadCategories(countryCode) {
        this.ordersService.getCategoriesFilter(this.currentUser.UserName, countryCode)
            .subscribe(
                data => {
                    this.fragmencesModel.fragmences.Category = data;
                    this.fragmencesModel.fragmences.SubCategory = [];
                    this.fragmencesModel.fragmences.MechantiseCategory = [];
                    this.fragmencesModel.fragmences.Brand = [];
                    this.fragmencesModel.category = 0;
                    this.fragmencesModel.subCategory = 0;
                    this.fragmencesModel.merchandiseCategory = 0;
                    this.fragmencesModel.brand = 0;
                },
                error => {
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private onCategoryChange() {
        this.loading = true;
        //this.fragmencesModel.fragmences.SubCategory = [];
        this.fragmencesModel.subCategory = 0;
        this.ordersService.getSubCategoriesFilter(this.fragmencesModel.category, this.currentUser.UserName, this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.fragmencesModel.fragmences.SubCategory = data;
                    this.fragmencesModel.subCategory = 0;
                    this.fragmencesModel.fragmences.MechantiseCategory = [];
                    this.fragmencesModel.fragmences.Brand = [];
                    this.fragmencesModel.merchandiseCategory = 0;
                    this.fragmencesModel.brand = 0;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private onSubCategoryChange() {
        this.loading = true;
        //this.fragmencesModel.fragmences.MechantiseCategory = [];
        this.fragmencesModel.merchandiseCategory = 0;
        this.ordersService.getMechantiseCategoriesFilter(this.fragmencesModel.category, this.fragmencesModel.subCategory, this.currentUser.UserName, this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    this.fragmencesModel.fragmences.MechantiseCategory = data;
                    this.fragmencesModel.merchandiseCategory = 0;
                    this.fragmencesModel.fragmences.Brand = [];
                    this.fragmencesModel.brand = 0;
                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private onMerchandiseCategoryChange() {
        this.loading = true;
        // this.fragmencesModel.fragmences.Brand = [];
        this.fragmencesModel.brand = 0;
        this.ordersService.getBrandFilter(this.fragmencesModel.category, this.fragmencesModel.subCategory, this.fragmencesModel.merchandiseCategory, this.currentUser.UserName, this.currentUser.CountryCode)
            .subscribe(
                data => {
                    this.loading = false;
                    this.fragmencesModel.fragmences.Brand = data;
                    this.fragmencesModel.brand = 0;
                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);

                });
    }

    private placeDraftOrder() {

        //this.alertService.error('Copying to place order');
        this.errorMessage('Copying to place order.');

        //setTimeout(() => {
        //    this.alertService.clearMessage();
        //}, 5000);

        this.ordersModel.newOrders.orders = this.ordersModel.selectedDraft.orders;
        let totalBonus = this.currentUser.ComplimentaryGoodsValue + this.currentUser.BonusValue + this.ordersModel.editInProgressAmt;
        if (totalBonus >= this.ordersModel.selectedDraft.TotalOrderValue) {
            this.loading = true;
            // this.ordersModel.placeOrder();
            this.ordersModel.selectedDraft.IsDraft = false;
            this.ordersModel.selectedDraft.OrderStatus = 'In Progress';
            // this.ordersModel.selectedDraft.OrderMasterId = 0;
            //console.log(this.ordersModel.selectedDraft);
            var yyyy = new Date().getFullYear().toString();
            var mm = (new Date().getMonth() + 1).toString(); // getMonth() is zero-based         
            var dd = new Date().getDate().toString();
            var hh = new Date().getHours().toString();
            var mn = new Date().getMinutes().toString();
            var sc = new Date().getSeconds().toString();

            let formData = new FormData();
            formData.append('OrderMasterId', this.ordersModel.selectedDraft.orderMasterId.toString());
            formData.append('OrderQty', this.ordersModel.selectedDraft.totalOrderQty.toString());
            formData.append('OrderStatus', 'In Progress');
            formData.append('OrderDate', (((dd[1] ? dd : "0" + dd[0]) + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + yyyy + "." + hh + "." + mn + "." + sc).toString()));
            formData.append('TotalOrderValue', this.ordersModel.selectedDraft.totalOrderPrice.toString());
            formData.append('Currency', this.currentUserCountryCurrency.toString());
            formData.append('Ordertype', this.ordersModel.selectedDraft.type.id.toString());
            formData.append('CustomerId', this.currentUser.CustomerId.toString());
            formData.append('IsDraft', 'false');

            formData.append('StoreCode', (this.ordersModel.selectedDraft.SelectedStore != 0 ? this.ordersModel.selectedDraft.SelectedStore.toString() : ''));
            formData.append('orders', JSON.stringify(this.ordersModel.selectedDraft.newOrders));
            this.ordersService.placeOrder(formData)
                .subscribe(
                    data => {
                        //this.loading = false;
                        this.ordersModel.selectedDraft = { 'orders': { '$values': [] } };
                        //this.alertService.success('Order Placed Successfully', false);
                        this.successMessage('Order has been placed successfully.');

                        this.authenticationService.login(this.currentUser.UserName, this.currentUser.Password)
                            .subscribe(
                                data => {
                                    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                    this.loadOrders();
                                    // setTimeout(() => {
                                    //     this.alertService.clearMessage();
                                    // }, 5000);
                                },
                                error => {
                                    //this.alertService.error(error);
                                    this.errorMessage(error.message);
                                    this.loading = false;
                                });
                    },
                    error => {
                        this.loading = false;
                        // this.alertService.error(error.message);
                        // setTimeout(() => {
                        //     this.alertService.clearMessage();
                        // }, 5000);
                        this.errorMessage(error.message);

                    });
        }
        else {
            // this.alertService.error('Order value exceeds available credits in your account');
            // setTimeout(() => {
            //     this.alertService.clearMessage();
            // }, 5000);
            this.errorMessage('Order value exceeds available credits in your account.');
        }
    }


    private deleteDratOrder() {
        this.loading = true;
        //console.log(this.ordersModel.selectedDraft.OrderMasterId);
        this.ordersService.deleteDraftOrder(this.ordersModel.selectedDraft.OrderMasterId)
            .subscribe(
                data => {
                    this.ordersModel.selectedDraft = { 'orders': { '$values': [] } };
                    //this.alertService.success('Draft Order Deleted Successfully', false);
                    this.successMessage('Draft Order deleted successfully.');
                    this.ordersModel.clearCurrentOrder();
                    this.loadOrders();
                    //setTimeout(() => {
                    //  this.alertService.clearMessage();
                    //}, 5000);

                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });

    }

    private UpdateUserEmail() {
        this.loading = true;
        this.ordersService.UpdateUserEmail(this.currentUser.PersonalEmail, this.currentUser.CustomerId)
            .subscribe(
                data => {
                    //this.alertService.success('Personal Email Updated Successfully', false);
                    this.loading = false;
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);

                    this.successMessage('Personal Email updated successfully.');

                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });

    }


    private UpdateUserPhone() {
        this.loading = true;
        if (this.currentUser.MobileNumber == "") {
            this.currentUser.MobileNumber = "NA";
        }
        this.ordersService.UpdateUserPhone(this.currentUser.MobileNumber, this.currentUser.CustomerId)
            .subscribe(
                data => {
                    //this.alertService.success('Personal Mobile Number Updated Successfully', false);
                    this.loading = false;
                    //setTimeout(() => {
                    //    this.alertService.clearMessage();
                    //}, 5000);
                    this.successMessage('Personal Mobile number updated successfully.');
                },
                error => {
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });

    }

    openModal(source) {
        this.display = "block";
        if (source == 'ChangePassword') {
            this.showChangePassword = 1;
            this.showFlexiBenefit = 0;
        }
        if (source == 'FlexiBenifit') {
            this.showChangePassword = 0;
            this.showFlexiBenefit = 1;
        }
    }
    loadeditfbrecord(fbeditrecord) {
        this.model.fbeditId = fbeditrecord.Id;
        this.model.fbeditCustId = fbeditrecord.CustId;

        this.model.fbeditReceiptDate = fbeditrecord.ReceiptDate.toString().split('T')[0];
        this.model.fbeditCategory = fbeditrecord.Category;
        var matchRecord = this.flexiBenefits.CategoryDropDown.filter(a => a.CategoryName === fbeditrecord.Category)[0];
        this.model.fbeditNTAFlag = !matchRecord.NTAFlag;
        this.model.fbeditTAFlag = !matchRecord.TAFlag;
        this.model.fbeditNTA = fbeditrecord.NTA;
        this.model.fbeditTA = fbeditrecord.TA;
    }
    OnFBEditRecordChange(categoryName) {
        var matchRecord = this.flexiBenefits.CategoryDropDown.filter(a => a.CategoryName === categoryName)[0];
        if (matchRecord != undefined) {
            this.model.fbeditNTA = 0.00;
            this.model.fbeditTA = 0.00;
            this.model.fbeditNTAFlag = !matchRecord.NTAFlag;
            this.model.fbeditTAFlag = !matchRecord.TAFlag;
        }
    }
    openFBEditModal(fbRecord) {
        const dialogRef = this.dialog.open(ModalComponent, { data: { fbRecord: fbRecord }, disableClose: true });
        dialogRef.afterClosed().subscribe((submit) => {
            //Console.log(submit);
        })
    }

    submitUpdateFBRequest(form: NgForm) {
        let formData = new FormData();
        formData.append('Id', this.model.fbeditId);
        formData.append('CustId', this.model.fbeditCustId);
        formData.append('ReceiptDate', this.model.fbeditReceiptDate);
        formData.append('Category', this.model.fbeditCategory);
        formData.append('NTA', this.model.fbeditNTA);
        formData.append('TA', this.model.fbeditTA);
        this.flexibenefitService.UpdateFlexiBenefit(formData)
            .subscribe(
                data => {
                    //if(data.m_Item1 == true){
                    if (data == true || data.m_Item1 == true) {
                        this.display = "none";
                        this.model = {};
                        form.resetForm();
                        this.loading = false;
                        this.successMessage('Flexi Benefit Info Saved');
                        this.searchFlexiBenefit();
                        this.loadFlexiBenefitValues();
                        //this.ResetFlexiBenefitForm(0);
                        //this.loadFlexiBenefit()
                        // if(this.IsAdmin){
                        //     //this.resetsearchFlexiBenefit(0);
                        //     //this.loadAllFlexiBenefit(this.fbCustomSearch);
                        // }
                    }
                    else {
                        this.display = "none";
                        this.model = {};
                        form.resetForm();
                        this.errorMessage(data.m_Item2);
                    }

                },
                error => {
                    this.display = "none";
                    this.model = {};
                    form.resetForm();
                    this.loading = false;
                    // this.alertService.error(error.message);
                    // setTimeout(() => {
                    //     this.alertService.clearMessage();
                    // }, 5000);
                    this.errorMessage(error.message);
                });
    }

    onCloseHandled(form: NgForm) {

        this.display = "none";
        form.resetForm();
    }

    onMarketClose(form: NgForm) {
        this.subscribemarket('N', form);
    }

    changePassword(form: NgForm) {
        if (this.model.newPassword == this.model.cnfPassword) {
            this.loading = true;
            this.authenticationService.changepassword(this.model.oldpassword, this.model.newPassword)
                .subscribe(
                    data => {
                        //console.log(data);
                        if (data != null) {
                            //this.alertService.success('Password Updated  Successful', true);
                            this.successMessage('Password Update - successful.');
                            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                            this.currentUser.Password = this.model.newPassword;
                            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                            this.display = "none";
                            this.model = {};
                            form.resetForm();

                        } else {
                            //this.alertService.error('Password Updated  Failed');
                            this.errorMessage('Password Update - Failed');
                        }
                        this.loading = false;

                    },
                    error => {
                        this.errorMessage(error.message);
                        this.loading = false;
                    });
        }
        else {
            // this.alertService.error("New Password & Confirm Password Should be same");
            this.errorMessage('New Password & Confirm Password Should be same.');
            // setTimeout(() => {
            //     this.alertService.clearMessage();
            // }, 5000);
        }
    }
    subscribemarket(marketFlg: String, form: NgForm) {
        this.loading = true;
        this.ordersService.SubscribeMarket(marketFlg, this.model.mobile, this.model.email, this.currentcustomerId.toString())
            .subscribe(
                data => {

                    this.markerconstdisplay = "none";
                    this.model = {};
                    form.resetForm();
                    this.loading = false;
                    this.marketingdisable = true
                    this.marketcheck = true;
                    this.placeOrder();

                },
                error => {
                    this.errorMessage(error.message);
                    this.loading = false;
                });
    }
    subscribeMarketChange(event: any) {

        var marketFlg = 'Y';
        if (event.target.checked) {
            marketFlg = 'N';
        }
        this.loading = true;
        this.ordersService.SubscribeMarket(marketFlg, null, null, this.currentcustomerId.toString())
            .subscribe(
                data => {
                    this.loading = false;
                },
                error => {
                    this.errorMessage(error.message);
                });

    }

    copyCompletedOrder(i: number) {
        this.ordersModel.editCompletedOrder(i, this.currentUser);
        //this.editOrCopyOrder = true;
        this.actionOnOrder = 'Copy';
        //this.copyOrder = true;
        //this.navigatingFromEdit = true;
        //this.selectTab(2);
        $('#New').tab('show');
    }

    editInProgressOrder(i: number) {
        this.ordersModel.editInProgressOrder(i);
        //this.editOrCopyOrder = true;
        this.actionOnOrder = 'Edit';
        //this.editOrder = true;
        //this.navigatingFromEdit = true;
        //this.selectTab(2);
        $('#New').tab('show');
    }

    //initializeNewFB(){
    //this.flexiBenefits.initializeNewFB();
    //}

    deleteInProgressOrder(i: number) {
        this.loading = true;
        this.ordersModel.deleteInProgressOrder(i);

        this.ordersService.deleteInProgressOrder({
            'OrderMasterId': this.ordersModel.orderMasterId,
            'TotalOrderValue': this.ordersModel.totalOrderPrice,
            'CustomerId': this.currentUser.CustomerId,
        }).subscribe(
            data => {
                //this.alertService.success('Order Deleted Successfully', false);
                this.loadOrders();
                if (data) {
                    this.currentUser.ComplimentaryGoodsValue += this.ordersModel.totalOrderPrice;
                    this.currentUser.ComplimentaryGoodsValue = parseFloat(this.currentUser.ComplimentaryGoodsValue.toFixed(2));
                }
                this.ordersModel.clearCurrentOrder();
                this.loadOrders();
                //setTimeout(() => {
                //    this.alertService.clearMessage();
                //}, 5000);
                this.successMessage('Order deleted successfully.');

            },
            error => {
                this.loading = false;
                this.errorMessage(error.message);
                //setTimeout(() => {
                //    this.alertService.clearMessage();
                //}, 5000);

            });
    }

    selectTab(index: number): void {
        this.selectedIndex = index;
    }

    /*
    tabChanged = (tabChangeEvent): void => {
        if (!this.navigatingFromEdit) {
            if (this.editOrCopyOrder) {
                this.ordersModel.totalOrderQty = 0;
                this.ordersModel.totalOrderPrice = 0;
                this.ordersModel.selectDraftOrder(this.ordersModel.draftOrderMasterId);
                this.editOrCopyOrder = false;
                this.editOrder = false;
                this.copyOrder = false;
                this.ordersModel.editInProgressAmt = 0;
            }
        }

        this.navigatingFromEdit = false;
        this.selectedIndex = tabChangeEvent.index;
    }*/


    successMessage(message) {
        this.toastr.success(message, 'Success');
    }

    errorMessage(message) {
        this.toastr.error(message, 'Error', { enableHtml: true });
    }


    private sendCancel($event: any): void {
        //return false;
    }

    confirmEvent(showConfirm: boolean) {
        if (showConfirm) {
            //console.log("confirmEvent : " + this.event);
            this.confirmBox.hide();

            if (this.event == 'deleteDratOrder')
                this.deleteDratOrder();

            if (this.event == 'deleteInProgressOrder')
                this.deleteInProgressOrder(this.orderId);

            if (this.event == 'updateOrder')
                this.validateMarketPlace();

            if (this.event == 'placeOrder')
                this.validateMarketPlace();

            if (this.event == 'saveAsDraft')
                this.saveDraft();

            // if (this.event == 'approveFlexiBenefit')
            //     this.approveFlexiBenefit();

            // if (this.event == 'rejectFlexiBenefit')
            //     this.rejectFlexiBenefit();

            if (this.event == 'deleteFlexiBenefit')
                this.deleteFlexiBenefit();

            if (this.event == 'submitClaimConfirm')
                this.validateFlexiBenefit();
        }
    }



    orderDeleteConfirm(orderId: any) {
        //console.log(this.orderId);

        //this.orderId = orderId;
        //this.event = 'deleteInProgressOrder'
        //this.confirmBox.show();
    }

    orderPlaceConfirm() {
        this.authenticationService.ValidateDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        localStorage.clear();
                        this.authenticationService.logout();
                        this.router.navigate(["login"]);
                    } else {
                        if (this.validateStore()) {
                            this.event = 'placeOrder'
                            this.confirmBox.show();
                        }
                    }
                }
            );
    }

    orderUpdateConfirm() {
        this.authenticationService.ValidateDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        localStorage.clear();
                        this.authenticationService.logout();
                        this.router.navigate(["login"]);
                    } else {
                        if (this.validateStore()) {
                            this.event = 'updateOrder'
                            this.confirmBox.show();
                        }
                    }
                }
            );
    }

    orderSaveAsDraftConfirm() {
        this.authenticationService.ValidateDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        localStorage.clear();
                        this.authenticationService.logout();
                        this.router.navigate(["login"]);
                    } else {
                        if (this.validateStore()) {
                            this.event = 'saveAsDraft'
                            this.confirmBox.show();
                        }
                    }
                }
            );
    }

    draftOrderDeleteConfirm() {
        this.authenticationService.ValidateDownTime()
            .subscribe(
                data => {
                    if (data === true) { //true - down, false - up
                        localStorage.clear();
                        this.authenticationService.logout();
                        this.router.navigate(["login"]);
                    } else {
                        this.event = 'deleteDratOrder';
                        this.confirmBox.show();
                    }
                }
            );
    }
}